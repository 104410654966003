!function() {

  UC.components.GoogleApi = function(options) {
    this.options = options || {};
  };

  UC.components.GoogleApi.prototype = {

    authorize: function(callback) {
      const self = this;
      this.callback = callback;

      if (!window.google || !window.google.accounts || !window.google.accounts.id) {
        console.warn('google identity service is not initialized yet.');
        return;
      }

      if (this.options.backdrop) {
        this.$backdrop = $('<div class="modal-backdrop in" style="z-index: 9998"></div>');
      }

      if (!window.google_id_initialized) {
        console.info('google.accounts.id.initialize');

        google.accounts.id.initialize({
          client_id: UC.constants.google_client_id,
          auto_select: false,
          ux_mode: 'popup',
          cancel_on_tap_outside: false,
          callback: function (resp) {
            console.info('google.accounts.id.callback');
            const instance = window.google_api_instance;
            if (instance) {
              if (instance.$backdrop) {
                instance.$backdrop.remove();
              }
              instance.handleAuthResult(resp, instance.callback);
              window.google_api_instance = null;
            }
          }
        });

        window.google_id_initialized = true; // 'initialize' must be called once on a page according to Google
      }

      window.google_api_instance = self;

      google.accounts.id.prompt((notification) => {
        if (notification.isDisplayed() && self.$backdrop) {
          $('body').append(self.$backdrop);
        }
        if (notification.isSkippedMoment() || notification.isNotDisplayed()) {
          if (self.$backdrop) {
            self.$backdrop.remove();
          }
          document.cookie = 'g_state={}; max-age=0; path=/';
          const context = this.options.context || 'signin';
          if (notification.isNotDisplayed()) {
            console.warn(notification);
            if (window.Bugsnag && notification.getNotDisplayedReason() !== 'opt_out_or_no_session') {
              Bugsnag.notify(new Error('google.accounts.id.prompt was not displayed (' + notification.getNotDisplayedReason() + ')'), function (event) {
                event.addMetadata('notification', {NotDisplayedReason: notification.getNotDisplayedReason()})
              });
            }
          }
          callback({
            status: `${context}_${notification.isSkippedMoment() ? 'skipped' : 'not_displayed'}`,
            provider: 'google_oauth2',
            googleErrorMessage: this.buildErrorMessage(notification)
          })
        }
      });
    },

    buildErrorMessage: function(notification) {
      const getNotDisplayedReason = notification.getNotDisplayedReason()
      const context = this.options.context || 'signin';
      const contextWords = context === 'signup' ? 'sign up' : 'sign in';
      const message = `You can not ${contextWords} with Google at the moment, please ${contextWords} using your email and password instead.`;
      if (notification.isNotDisplayed() && getNotDisplayedReason === 'opt_out_or_no_session') {
        if ((navigator.userAgent || '').match(/chrome|chromium|crios/i)) {
          return `In order to ${contextWords} with Google in Chrome you need to turn on Chrome sync with your Google account first. Alternatively, you can ${contextWords} with Google using different browser.`;
        } else {
          return context === 'signup' ? `${message} You will be able to link your Google account later.` : message;
        }
      } else if (notification.isNotDisplayed() && getNotDisplayedReason === 'unknown_reason') {
        return `Google ${contextWords} isn't working right now, possibly due to extensions like 1Password or AdBlocker. Try temporarily disabling them to resolve the issue.`;
      }
      return message;
    },

    handleAuthResult: function(resp, callback) {
      if (resp && !resp.error) {

        if (this.options.next_url) {
          resp.next_url = this.options.next_url;
        } else if (UC.constants.returnUrl) {
          resp.returnUrl = UC.constants.returnUrl;
        }

        $.post('/auth/google_identity/callback', resp, function(data) {
          callback(data);
        });
      } else {
        UC.utils.showNotification("There was an error connecting to Google for authentication.", UC.constants.results.failure);
      }
    }
  };
}();

