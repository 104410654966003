!function() {

  var defaults = {
    maxFiles: 5,
    maxSize: 10000000,
    onInit: function() {},
    onUploadComplete: function() {},
    createPath: '/documents',
    filePickerKey: 'AlcqNbl3HRIOvm8gG7H1Oz',
    skipPathPrefix: false,
    upload_path: null,
    userId: null,
    jobId: null
  };

  var filepicker_loading = false;
  var filepicker_dfd = $.Deferred();

  var Upload = UC.components.Upload = function(options) {
    this.options = $.fn.extend({}, defaults, options);
    this.uploading = null;
    this.upload_path = this.options.upload_path || '/';
    this.source_type = this.options.source_type;

    // default to UC objects, but allow overriding
    this.user_id = (UC.user && !options.userId) ? UC.user.id : options.userId ;
    this.job_id = (UC.job && !options.jobId) ? UC.job.id : options.jobId ;
    this.attorney_id = options.attorneyId;

    if (!this.options.skipPathPrefix) {

      if (this.user_id) {
        this.upload_path += 'user_{0}/'.format(this.user_id);
      }

      if (this.job_id) {
        this.upload_path += 'job_{0}/'.format(this.job_id);
      }
    }

    if (!filepicker_loading) {
      filepicker_loading = true;
      var key = this.options.filePickerKey;

      // load in the filepicker JS!!
      $.getScript('https://api.filepicker.io/v2/filepicker.js', function() {
        // set our magical api key
        filepicker.setKey(key);
        // resolve our stuff please
        filepicker_dfd.resolve();
      });
    }

    if (window.filepicker) {
      this.options.onInit(this);
    } else {
      $.when(filepicker_dfd).done(function() {
        this.options.onInit(this);
      }.bind(this));
    }
  };

  Upload.prototype = {

    constructor: Upload,

    makeDropPane: function($el, options) {
      if (!window.filepicker) {
        console.log('filepicker was not initialized yet.')
        return;
      }

      var last_enter;
      this.$el = $el;
      options = options || {};

      $('body').on('drop dragover dragleave', function(e){
        e.preventDefault();
      });

      $el.on('dragenter', function(e) {
        if (this.uploading && this.uploading.state() == 'pending') {
          return false;
        }
        last_enter = e.target;
        $el.addClass('dragging');
      }.bind(this));

      $el.on('dragleave', function(e) {
        if (last_enter === e.target) {
          $el.removeClass('dragging');
        }
      }.bind(this));

      var existing = options.onStart;
      var existing_error = options.onError;

      options.onStart = function(files) {
        $el.removeClass('dragging');
        $el.addClass('uploading');
        this.uploading = $.Deferred();
        if (typeof existing === 'function') {
          existing(files);
        }
      }.bind(this);

      options.onError = function(type, message) {
        $el.removeClass('dragging');

        if (typeof existing_error === 'function') {
          existing_error(type, message);
        } else {
          this.error(type, message);
        }
      }.bind(this);

      filepicker.makeDropPane($el, $.extend({
        multiple: true,
        maxSize: this.options.maxSize,
        maxFiles: this.options.maxFiles,
        location: 'S3',
        path: this.upload_path,
        // default success action, can be overriden
        onSuccess: this.process.bind(this)
      }, options));
    },

    openFilePicker: function(options) {
      if (!window.filepicker) {
        console.log('filepicker was not initialized yet.')
        return;
      }

      filepicker.pickAndStore($.extend({
        services: ['COMPUTER', 'BOX', 'DROPBOX', 'EVERNOTE', 'GOOGLE_DRIVE', 'SKYDRIVE'],
        maxSize: this.options.maxSize,
        maxFiles: this.options.maxFiles,
        multiple: this.maxFiles > 1
      }, options), {
        location: 'S3',
        path: this.upload_path
      }, function(files) {
        this.uploading = $.Deferred();
        this.process(files);
      }.bind(this));
    },

    process: function(files) {

      if (typeof this.options.beforeProcess === 'function') {
        this.options.beforeProcess();
      }

      var req = UC.net.post(this.options.createPath, {
        files: files,
        job_id: this.job_id,
        attorney_id: this.attorney_id,
        source_type: this.source_type
      }, function(response) {
        this.options.onUploadComplete(null, response.documents);

        if (this.uploading) {
          this.uploading.resolve();
        }
      }.bind(this)).fail(function(xhr) {
        if (xhr.responseJSON) {
          this.options.onUploadComplete(xhr.responseJSON.message);
        } else {
          this.options.onUploadComplete('There was an error during the upload. Please retry a little later');
        }

        if (this.uploading) {
          this.uploading.resolve();
        }
      }.bind(this));

      if (typeof this.options.processFailureCallback === 'function') {
        req.fail( function(res, err, message) {
          if (res.responseJSON && typeof res.responseJSON.message === 'string') {
            message = res.responseJSON.message;
          }
          this.options.processFailureCallback(message);
        }.bind(this));
      }
    },

    error: function(type, message) {

      if (type === 'WrongSize') {
        var mb = (this.options.maxSize / 1024 / 1024).toFixed(0);
        message = 'File too large. Must be less than {0} Mb'.format(mb);
      }

      UC.utils.showNotification(message, UC.constants.results.failure);
    },

    destroy: function(id, callback) {
      UC.net.destroy('/documents/{0}'.format(id), null, function(err, message) {
        if (typeof callback === 'function') callback(err, message);
      }.bind(this));
    }
  };
}();
