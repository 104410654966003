UC.views.AccountIdentityVerificationDocument = Backbone.View.extend({

  events: {
    'click #btnPickDocument' : 'openFilePicker'
  },

  initialize: function() {
    this.components = {};
    this.components.pickFileLoader = $('#btnPickDocument').buttonLoader();
    this.uploader = new UC.components.Upload({
      maxFiles: 1,
      source_type: "identity_verification",
      upload_path: "verification_documents/",
      createPath: "save_identity_verification_document",
      beforeProcess: this.showLoader.bind(this),
      processFailureCallback: this.processFailure.bind(this),
      onUploadComplete: this.onUploadComplete.bind(this)
    });
    this.tosCheckBox = new UC.partials.StripeTosAcceptedCheckbox({
      el: this.$el,
      errorMessage: 'Must check to proceed with upload'
    });
  },

  openFilePicker: function(e) {
    e.preventDefault();
    UC.utils.hideValidationErrors('js-input');
    if (this.tosCheckBox.isMissingOrChecked()) {
      this.uploader.openFilePicker({extensions: ['.png', '.jpeg']});
    }
  },

  onUploadComplete: function(err, documents) {
    if (err) {
      this.hideLoader();
      UC.utils.showNotification(err, UC.constants.results.failure);
    }
  },

  processFailure: function(err) {
    this.hideLoader();
    UC.utils.showNotification(err, UC.constants.results.failure);
  },

  hideLoader: function() {
    this.components.pickFileLoader.hideLoader();
  },

  showLoader: function() {
    this.components.pickFileLoader.showLoader();
  }

});
