UC.views.AccountEdit = Backbone.View.extend({

  events: {
    'submit form' : 'submitBasicInfo',
    'click .js-copy-link-text': 'copyReferralLink',
    'click #aDisconnectLinkedin' : 'onDisconnectLinkedinClick',
    'click #btnConnectLinkedin' : 'onConnectLinkedinClick',
    'click #btnConnectGoogle' : 'onConnectGoogleClick',
    'click #aDisconnectGoogle' : 'onDisconnectGoogleClick',
    'click #btnShowUploadResume' : 'showResumeUpload'
  },

  initialize: function() {
    this.$el.find('select').select2({width: "100%"});
    this.$form = this.$el.find('#formBasicInfo');
    this.$btnShowUploadResume = this.$el.find('#btnShowUploadResume');
    this.formLoader = this.$el.find('#btnSave').buttonLoader();
    this.googleLoader = this.$el.find('#btnConnectGoogle').buttonLoader();
    this.linkedInLoader = this.$el.find('#btnConnectLinkedin').buttonLoader();
    this.$firstName = this.$el.find('#txtFirstName');
    this.$lastName = this.$el.find('#txtLastName');
    this.$phone= this.$el.find('#txtPhone');
    this.$vanityProfileUrl = this.$el.find('#txtVanityProfileUrl');
    this.$aDisconnectLinkedin = this.$el.find('#aDisconnectLinkedin');
    this.$btnConnectLinkedin = this.$el.find('#btnConnectLinkedin');
    this.$aDisconnectGoogle = this.$el.find('#aDisconnectGoogle');
    this.$btnConnectGoogle = this.$el.find('#btnConnectGoogle');

    this.addressForm = new UC.partials.AddressForm({
      el: '#formBasicInfo'
    });

    if (this.$btnShowUploadResume.length > 0) {
      this.resumeLoader = this.$btnShowUploadResume.buttonLoader();
      this.$btnShowUploadResume.tooltip();

      this.uploader = new UC.components.Upload({
        source_type: 'edit_account_resume',
        upload_path: '{0}/resumes/'.format(UC.user.id),
        createPath: '/account/upload_resume',
        maxFiles: 1,
        processFailureCallback: this.resumeUploadFailure.bind(this),
        onUploadComplete: this.resumeUploadSuccess.bind(this),
        filePickerKey: 'APtS2ec0eQhieFIz8e0xhz',
        skipPathPrefix: true,
        mimetypes: ['document/*', 'text/*']
      });
    }
  },

  accountFormData: function () {
    var account = this.$form.serializeJSON();
    var address = { Address: this.addressForm.getAddress() };
    var organization = this.organizationInfo();
    var vanityProfileUrl = { VanityProfileUrl: this.$vanityProfileUrl.val() };

    return $.extend({}, account, address, organization, vanityProfileUrl);
  },

  copyReferralLink: function (e) {
    var targetSelector = e.target.dataset.targetSelector;
    var targetEl = $(targetSelector).first();
    targetEl.select();
    document.execCommand('copy');
  },

  submitBasicInfo: function (e) {
    e.preventDefault();

    var addressDfd = new $.Deferred();
    var formDfd = new $.Deferred();

    $.when.apply(this, [formDfd, addressDfd]).done(function() {
      this.formLoader.showLoader();
      this.saveAccountData();
    }.bind(this));

    this.validateAccount(formDfd);
    this.addressForm.validate(addressDfd);
  },

  validateAccount: function (dfd) {
    UC.utils.clearValidationErrors('form-group');
    var errors = [];
    var account = this.accountFormData();
    var vanityProfileUrl = this.$vanityProfileUrl.val();

    var fields = ['FirstName', 'LastName', 'Phone', 'CompanyName'];
    for (var i = 0; i < fields.length; i++) {
      if ('string' === typeof account[fields[i]]) {
        account[fields[i]] = account[fields[i]].trim();
      }
    }

    if (!account.FirstName) {
      errors.push({className:
        'form-group',
        element: this.$firstName,
        text: 'Missing first name'
      });
    }

    if (!account.LastName) {
      errors.push({className:
        'form-group',
        element: this.$lastName,
        text: 'Missing last name'
      });
    }

    if (account.Phone && !UC.utils.validatePhone(account.Phone)) {
      errors.push({className:
        'form-group',
        element: this.$phone,
        text: 'Invalid phone number'
      });
    }

    if (UC.utils.isAttorney()) {
      if (vanityProfileUrl) {
        if (!/^[a-zA-z0-9\.+-]{5,30}$/.test(account.VanityProfileUrl)) {
          errors.push({
            element: this.$vanityProfileUrl,
            text: 'Please provide a name between 5-30 characters, including only letters, numbers, and the symbols . + -'
          });
        }
      }
    }

    UC.utils.showValidationErrors(errors);
    if (errors.length > 0) {
      dfd.reject();
    } else {
      this.account = account;
      dfd.resolve();
    }
  },

  organizationInfo: function () {
    return {
      CompanyIndustry: $('#selectIndustry').val(),
      CompanyName: $('#txtCompanyName').val(),
      CompanySizeRange: $('#js-selectCompanySizeRange').val()
    };
  },

  onConnectLinkedinClick: function (e) {
    e.preventDefault();
    this.linkedInLoader.showLoader();

    new UC.components.Authentication().logonLinkedIn(
        {
          callback: function (response) {
            this.linkedInLoader.hideLoader();
            if (response.status === 'connected') {
              UC.utils.showNotification('Your LinkedIn account was successfully connected.', UC.constants.results.success);
              this.$aDisconnectLinkedin.removeClass('hide');
              this.$btnConnectLinkedin.addClass('hide');
            } else if (response.status === 'invalid_connection') {
              UC.utils.showNotification(response.message, UC.constants.results.failure);
            }
          }.bind(this),
        }
    );
  },

  onDisconnectLinkedinClick: function (e) {
    e.preventDefault();

    function onConfirm(response) {
      if (response) {
        UC.net.post('/auth/linkedin/disconnect', null, function (data) {
          UC.utils.showNotification(data.message, UC.constants.results.success);
          this.$aDisconnectLinkedin.addClass('hide');
          this.$btnConnectLinkedin.removeClass('hide');
        }.bind(this));
      }
    }

    UC.utils.showConfirmModal('Confirm', 'Are you sure you wish you remove the connection to your LinkedIn profile?<br/>You will not be able to login using LinkedIn.', onConfirm.bind(this));
  },

  onConnectGoogleClick: function (e) {
    e.preventDefault();
    this.googleLoader.showLoader();

    new UC.components.Authentication().logonGoogle(
        {
          context: 'signin',
          backdrop: true,
          callback: function (response) {
            this.googleLoader.hideLoader();
            if (response.status === 'connected') {
              UC.utils.showNotification('Your Google account was successfully connected.', UC.constants.results.success);
              this.$aDisconnectGoogle.removeClass('hide');
              this.$btnConnectGoogle.addClass('hide');
            } else if (response.status === 'invalid_connection') {
              UC.utils.showNotification(response.message, UC.constants.results.failure);
            } else if (response.status !== 'signin_skipped') {
              UC.utils.showNotification(
                  response.googleErrorMessage || 'There was an error connecting Your Google account, please try again later.',
                  UC.constants.results.failure
              );
            }
          }.bind(this),
        }
    );
  },

  onDisconnectGoogleClick: function (e) {
    e.preventDefault();

    function onConfirm(response) {
      if (response) {
        UC.net.post('/auth/google_oauth2/disconnect', null, function (data) {
          UC.utils.showNotification(data.message, UC.constants.results.success);
          this.$aDisconnectGoogle.addClass('hide');
          this.$btnConnectGoogle.removeClass('hide');
        }.bind(this));
      }
    }

    UC.utils.showConfirmModal('Confirm', 'Are you sure you wish you remove the connection to your Google profile?<br/>You will not be able to login using Google.', onConfirm.bind(this));
  },

  saveAccountData: function () {
    var account = this.accountFormData();

    UC.net.post('/account/update', {account: account}, function (res) {
      UC.utils.showNotification(res.message, UC.constants.results.success);
    }).always(function() {
      this.formLoader.hideLoader();
    }.bind(this));
  },

  showResumeUpload: function(e) {
    e.preventDefault();
    this.uploader.openFilePicker({});
  },

  resumeUploadFailure: function(err) {
    this.resumeLoader.hideLoader();
    UC.utils.showNotification(err, UC.constants.results.failure);
  },

  resumeUploadSuccess: function() {
    location.reload();
  }

});
