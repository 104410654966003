UC.views.AccountPhoto = Backbone.View.extend({

  initialize: function() {
    this.setPhotoUploadWidget();

    if (location.hash == '#checklist') {
      $('#btnSavePhoto').text('Save & Continue');
    }
    else if (location.hash == '#edit') {
      $('#btnFilepicker').trigger('click');
    }
  },

  setPhotoUploadWidget: function() {
    var uploadPath = '{0}/images/'.format(UC.user.id);

    var photoWidgetOptions = {
      s3UploadPath: uploadPath,
      openFilePickerBtnSelector: '#btnFilepicker',
      ucStoreFileInfoPath: '/account/file_uploaded',
      ucStoreCroppedPhotoUrl: '/account/save_photo',
      uploadedImagePreviewSelector: '#imgNewPhoto',
      currentPhotoPanelSelector: '#panelCurrentPhoto',
      newPhotoPanelSelector: '#panelNewPhoto',
      savePhotoBtnSelector: '#btnSavePhoto',
      userId: UC.user.id,
      maxSize: UC.constants.maxPhotoSize,
      radminUpdate: false
    };

    var photoWidget = new UC.photoUploaderWidget(photoWidgetOptions);

    $.getScript('https://api.filepicker.io/v2/filepicker.js', function() {
      filepicker.setKey('APtS2ec0eQhieFIz8e0xhz');

      photoWidget.init();
    });
  }
})
