UC = UC || {};

UC.plaidBankVerification = function(args) {
  this.openPlaidLinkSelector = args.openPlaidLinkSelector;
  this.successRedirectUrl = args.successRedirectUrl || '/account/billing';
  this.successCallback = args.successCallback;
  this.$openPlaidLinkElement = $(this.openPlaidLinkSelector);
  this.clientName = "UpCounsel";
  this.addPaymentMethodIdToRedirectUrl = args.addPaymentMethodIdToRedirectUrl || false;
  this.showDefaultButtonLoader = !!args.showDefaultButtonLoader;
  if (this.showDefaultButtonLoader) {
    this.buttonLoader = this.$openPlaidLinkElement.buttonLoader();
  }

  this.plaidOptions = {
    clientName: this.clientName,
    selectAccount: true,
    product: 'auth',
    apiVersion: 'v2',
    key: args.plaidPubKey,
    env: args.plaidEnv,
    onSuccess: this.plaidLinkAuthSuccess.bind(this),
    onExit: this.plaidLinkExit.bind(this),
    onLoad: this.plaidLinkLoad.bind(this)
  };
};

UC.plaidBankVerification.prototype = {
  init: function() {
    if (window.Plaid) {
      this.initHandler();
    }
  },

  initHandler: function() {
    this.plaidLinkHandler = Plaid.create(this.plaidOptions);
    this.$openPlaidLinkElement.on('click', this.openPlaidLink.bind(this));
  },

  openPlaidLink: function(e) {
    e.preventDefault();
    this.plaidLinkHandler.open();
    this.$openPlaidLinkElement.trigger('plaidLinkOpen');

    if (this.showDefaultButtonLoader) {
      this.buttonLoader.showLoader();
    } else {
      this.$openPlaidLinkElement.addClass('loader-enabled');
    }

    UC.utils.recordKissEvent('plaid_link_modal_opened', { location: window.location.href })
  },

  plaidLinkAuthSuccess: function(publicToken, metadata) {
    UC.net.post('/account/plaid_auth', { public_token: publicToken, account_id: metadata.account_id })
      .done(function(data) {
        if (typeof this.successCallback !== 'undefined') {
          this.successCallback();
        } else if (data && data.payment_method_id) {
          var href = this.successRedirectUrl;
          if(this.successRedirectUrl.includes('?')) {
            href += '&'
          } else {
            href += '?'
          }

          window.location.href = href + 'payment_method=' + data.payment_method_id;
        } else {
          window.location.href = this.successRedirectUrl;
        }

        this.$openPlaidLinkElement.trigger('plaidLinkAuthSuccess');
      }.bind(this))
      .fail(function() {
        this.$openPlaidLinkElement.trigger('plaidLinkAuthFailure');
        if (this.showDefaultButtonLoader) {
          this.buttonLoader.hideLoader();
        } else {
          this.$openPlaidLinkElement.removeClass('loader-enabled');
        }

        UC.utils.recordKissEvent('plaid_bank_auth_failed', { location: window.location.href })
      }.bind(this));
  },

  plaidLinkExit: function() {
    this.$openPlaidLinkElement.trigger('plaidLinkExit');
    if (this.showDefaultButtonLoader) {
      this.buttonLoader.hideLoader();
    } else {
      this.$openPlaidLinkElement.removeClass('loader-enabled');
    }

    UC.utils.recordKissEvent('user_exited_plaid_link_modal', { location: window.location.href })
  },

  plaidLinkLoad: function() {
    this.$openPlaidLinkElement.trigger('plaidLinkModuleLoaded');
  }
};
