import {findAndActivateEzoicAdPlaceholders, injectEzoicAdPlaceholders} from '../../../utils/ezoic_ads'

UC.partials.ContentPagesShowArticle = Backbone.View.extend({

  stickyStart: 0,
  showStickySection: false,

  initialize: function () {
    UC.utils.recordKissEvent('viewed an article', {
      'article_id' : this.$el.find('#ArticleId').val(),
      'author_attorney_id': this.$el.find('#AuthorId').val(),
    });

    $('.article-body a').on('click propogateTracking', function(e) {
      UC.utils.handleGenericClick($(this), 'article-internal-link-clicked');
    });

    // TODO: Remove when Samsung fixes this issue - https://github.com/SamsungInternet/support/issues/56
    Function.prototype.ToString = function () { return this.toString(); }

    var that = this;
    var header = $('.document');

    this.initTocComponents();

    if($('.js-sticky-cta-test').length > 0) {
      this.showStickyFooter = true;
      this.optOutStickyFooter = false;
      if(UC.utils.isMobileViewPort()) {
        $('.js-cancel-sticky-footer').click(function() {
          this.hideStickyCtaFooter();
          this.optOutStickyFooter = true;
        }.bind(this));
      }
      UC.components.contentPage.setCtaBannerSize(header);
      $(document).scroll(function () {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      });
    }

    var $stickySection = $('.js-sticky-section');
    if($stickySection.length > 0) {
      this.stickyStart = 5;
      if (!$stickySection.hasClass('js-sticky-nav')) {
        var desc = $('.doc-description, #articleTitle');
        var offset = desc.offset();

        this.stickyStart = offset.top + desc.height();
      }
      $(window).scroll({start: this.stickyStart, view: this}, this.testScroll);
    }

    if (this.showStickyFooter) {
      $stickySection.on('show.bs.dropdown', function () {
        that.hideStickyCtaFooter();
      }).on('hide.bs.dropdown', function () {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      })

      $('#mobile-nav').on('mobile-nav-closed', function() {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      })
    }
    const isMobile = UC && UC.browser && typeof UC.browser.isMobile === 'function' && UC.browser.isMobile();
    const $stickyHeader = $('.sticky-cta-header');
    const stickyHeaderHeight = Math.max($stickyHeader.length && !isMobile ? $stickyHeader.height() : 0, 0);

    if (stickyHeaderHeight) {
      $('.sticky-section').css('top', stickyHeaderHeight);
    }

    // override TOC link clicks to offset the sticky header height
    $('#toc a').on('click', function (e) {
      const href = e.target.getAttribute('href');
      if (!href) {
        return;
      }
      try {
        const element = document.getElementById(href.substring(1));
        const stickySectionsExist = $('.sticky-section, .js-sticky-section').length > 0;
        let headerOffset = stickyHeaderHeight;
        if (isMobile && stickySectionsExist) {
          headerOffset = 48 + 41 /* $header-height-mobile + $toc-height-mobile */;
        }
        window.scrollBy({
          top: element.getBoundingClientRect().top - headerOffset - 20 + 1,
          behavior: "smooth"
        });
        e.preventDefault();
      } catch {
        console.warn('failed to scroll to Toc heading')
      }
    });

    if (UC.constants.ezoicEnabled) {
      injectEzoicAdPlaceholders('.article-body', 5, 105);

      findAndActivateEzoicAdPlaceholders();
    }
  },

  hideStickyCtaFooter: function() {
    this.showStickyFooter = false;
    UC.components.contentPage.removeCtaStickyFooter();
  },

  testScroll: function(event) {
    if ($(window).scrollTop() >= event.data.start) {
      if (!event.data.view.showStickySection) {
        event.data.view.showStickySection = true;
        $('.js-sticky-section').first().css({display:'block'});
      }
    } else {
      if (event.data.view.showStickySection) {
        event.data.view.showStickySection = false;
        $('.js-sticky-section').first().css({display:'none'});
      }
    }
  },

  initTocComponents: function() {
    if (!UC.constants.displayToc) {
      return;
    }

    const $affixPanel = $('.toc-column .panel');
    const affixAvailable = typeof $affixPanel.affix === 'function';
    const mobileHeaderHeight = 48 + 41; // $header-height-mobile + $toc-height-mobile
    const tocNavSelector = '#toc';

    $affixPanel.css('width', $('.toc-column').width());

    $(window).resize(function() {
      $affixPanel.css('width', $('.toc-column').width());

      if (affixAvailable) {
        $affixPanel.affix('checkPosition');
      }
    });

    if (typeof Toc === 'object') {
      Toc.init({
        $nav: $(tocNavSelector),
        $scope: $('.article-body'),
        startWithHeadingsLevel: 2
      });
    } else {
      $('.toc-column').hide();
      $('.content-column').toggleClass('col-md-7 col-md-9');
    }

    const $stickyHeader = $('.sticky-cta-header');
    const isMobile = UC && UC.browser && typeof UC.browser.isMobile === 'function' && UC.browser.isMobile();
    const scrollspyOffset = $stickyHeader.length && !isMobile ? $stickyHeader.height() : mobileHeaderHeight + 20;
    const $body = $('body')
    if (typeof $body.scrollspy === 'function') {
      $body.scrollspy({target: tocNavSelector, offset: scrollspyOffset + 20});
      if (isMobile) {
        $body.on('activate.bs.scrollspy', function (e) {
          if ($(e.target).find('li.active').length > 0) {
            return
          }
          const list = $('#toc').find('li');
          const index = list.index(e.target) + 1;
          const progress = Math.min(100, (index / list.length) * 100);
          $('.mobile-toc .progress-bar').css('width', `${progress}%`);
        })
      }
    }

    $affixPanel.on('affixed.bs.affix', function() {
      $affixPanel.css('position', 'fixed');
    }).on('affixed-top.bs.affix', function() {
      $affixPanel.css('position', 'relative');
    });

    if (affixAvailable) {
      $affixPanel.affix({
        offset: {
          top: isMobile ? mobileHeaderHeight : 65 /* $header-height-desktop */,
          bottom: function () {
            return document.getElementById("content").getBoundingClientRect().height - $('#articlePreview').height() - 270;
          }
        }
      });
    }
  }
});
