!function() {

  UC.partials.UsersForm = Backbone.View.extend({

    el: '#new_user',

    events: {
      'submit': 'validate',
      'click #btnGoogleRegister': 'registerGoogle',
      'click #btnLinkedInRegister': 'registerLinkedin',
      'click #toggleInternationalPanel': 'toggleInternationalPanel',
      'click #toggleZipCodePanel': 'toggleZipCodePanel'
    },

    routes: {
      validate_zip: '/account/valid_zip_code/{0}',
      email_exists: '/account/email_exists',
      users: '/users',
    },

    initialize: function(options) {
      this.options = options || {};

      this.loader = $('#btnRegister').buttonLoader();
      this.showZipCode = true;
      this.showPhone = false;

      this.recaptchaTags = $('#recaptchaTags');

      this.$('#user_Address_attributes_CountryCode').select2({
        placeholder: 'Select a Country',
        width: '100%'
      });

      // set return url
      this.next_url = this.$('#next_url').val();
      if (UC.constants.returnUrl) {
        this.next_url = UC.constants.returnUrl;
      }

      if (typeof document.referrer === 'string' && document.referrer.match(/\/for-lawyers$/i)) {
        $('#user_RegistrationSource').val(UC.constants.forLawyersPageRegistrationSourceType)
      }
    },

    onOauthAuthorize: function(data) {
      if (data.status === 'authenticated') {
        if (this.options.oauthLoginCallback) {
          this.options.oauthLoginCallback(data);
        } else {
          window.location = data.next_url;
        }
      } else if (data.status === 'unregistered') {
        var info = data.auth.info;

        var omniauthParams = {
          firstName: info.first_name || info.given_name,
          lastName: info.last_name || info.family_name,
          emailAddress: info.email,
          imageUrl: info.image || info.picture || info.picture_url
        };

        this.omniauth = data.auth;
        this.showOmniauthWelcome(omniauthParams);
      } else {
        switch (data.status) {
          case 'suspended':
            UC.utils.showNotification('Your account has been suspended. Please contact us for more information.', UC.constants.results.failure);
            break;
          case 'pending':
            UC.utils.showNotification('Your account is pending. Please check your email for an activation link.', UC.constants.results.warning);
            break;
          case 'signup_not_displayed':
            UC.utils.showNotification(data.googleErrorMessage, UC.constants.results.warning);
            break;
          case 'signin_not_displayed':
            UC.utils.showNotification(data.googleErrorMessage, UC.constants.results.warning);
            break;
          case 'signup_skipped':
          case 'signin_skipped':
            break;
          default:
            UC.utils.showNotification('Something went wrong', UC.constants.results.failure);
            break;
        }
      }
    },

    register: function(user) {
      var data = this.$el.serializeJSON();

      if (this.options.beforeRegister) this.options.beforeRegister(data);
      data.omniauth = this.omniauth;

      if (UC.constants.referralToken) {
        data.rt = UC.constants.referralToken;
      }

      if (!this.showZipCode) { // ensure zip is deleted for international address
        if(data.user && data.user.Address_attributes){
          delete data.user.Address_attributes.ZipCode;
        }
      }

      var self = this;
      UC.net.post(this.routes.users, data, function(result) {
        self.options.onRegister(result, data.user);
      }).fail(function(response) {
        self.loader.hideLoader();
        if (response && response.responseJSON && response.responseJSON.recaptcha) {
          self.recaptchaTags.html(response.responseJSON.recaptcha)
        }
      });
    },

    registerLinkedin: function(e) {
      e.preventDefault();

      new UC.components.Authentication().logonLinkedIn(
          {
            callback: this.onOauthAuthorize.bind(this)
          }
      );
    },

    registerGoogle: function(e) {
      e.preventDefault();

      var google_api = new UC.components.GoogleApi({
        next_url: this.next_url,
        context: 'signup',
        backdrop: true,
      });

      google_api.authorize(this.onOauthAuthorize.bind(this));
    },

    showOmniauthWelcome: function(params) {
      var $profileImage = this.$('#imgOmniauthProfile');

      this.$('#panelOmniauthButtons').addClass('hide');
      this.$('#panelOmniauthWelcome').removeClass('hide');
      this.$('#lblOmniauthName').text('{0} {1}'.format(params.firstName, params.lastName));

      if (params.imageUrl) {
        $profileImage.attr('src', params.imageUrl);
      } else {
        $profileImage.remove();
      }

      this.$('#user_FirstName').val(params.firstName);
      this.$('#user_LastName').val(params.lastName);
      this.$('#user_Email').val(params.emailAddress);
      this.validate(null, true);
    },

    toggleInternationalPanel: function(e) {
      e.preventDefault();
      this.showZipCode = false;
      this.$('#panelInternationalAddress').removeClass('hide');
      this.$('#panelZipCodeAddress').addClass('hide');
      this.$('#emailCol').toggleClass('col-sm-6 col-sm-12');
    },

    toggleZipCodePanel: function(e) {
      e.preventDefault();
      this.showZipCode = true;
      this.$('#panelInternationalAddress').addClass('hide');
      this.$('#panelZipCodeAddress').removeClass('hide');
      this.$('#emailCol').toggleClass('col-sm-12 col-sm-6');
    },

    resetInternationalAddressOption: function(visible) {
      this.showZipCode = true;
      this.$('#toggleInternationalPanel').toggle(visible);
      this.$('#emailCol').removeClass('col-sm-12').addClass('col-sm-6');
      this.$('#panelInternationalAddress').addClass('hide');
      this.$('#panelZipCodeAddress').removeClass('hide');
    },

    togglePhonePanel: function(id) {
      var $phonePanel = this.$('#panelPhoneNumber');

      if (id === 'user_Type_0') {
        $phonePanel.removeClass('hide');
        this.showPhone = true;
      } else {
        $phonePanel.addClass('hide');
        this.showPhone = false;
      }

      this.resetInternationalAddressOption(id !== 'user_Type_0');
    },

    validate: function(e, skipUserCreation, onValidationSuccess, keepErrors) {
      if (e) e.preventDefault();
      if (!keepErrors) UC.utils.clearValidationErrors('input-container');

      this.loader.showLoader();

      var form_dfd = new $.Deferred();
      var email_dfd = new $.Deferred();
      var address_dfd = new $.Deferred();
      var phone_dfd = new $.Deferred();

      this.validateForm(form_dfd);
      this.validateEmail(email_dfd);

      if (this.showZipCode) {
        this.validateZipCode(address_dfd);
      } else {
        this.validateInternational(address_dfd);
        this.$('#user_Address_attributes_ZipCode').val(""); // clear this value if international
      }

      if (this.showPhone && !_.isEmpty(phone_number = this.$('#user_Phone').val())) {
        this.validatePhone(phone_dfd, phone_number);
      } else {
        phone_dfd.resolve();
      }

      $.when(form_dfd, address_dfd, email_dfd, phone_dfd)
        .done(function (user) {
          if (!skipUserCreation) this.register(user);
          if ('function' === typeof onValidationSuccess) onValidationSuccess();
        }.bind(this))
        .fail(function() {
          this.loader.hideLoader();
          if (this.options.onRegistrationFailure) {
            this.options.onRegistrationFailure();
          }
        }.bind(this));
    },

    validateEmail: function(dfd) {
      var $email = this.$('#user_Email');
      var email = $email.val();

      function error(msg) {
        var errors = [{element: $email, text: msg}];
        UC.utils.showValidationErrors(errors, {className: 'input-container', scrollToFirst: false});
        dfd.reject();
      }

      if (UC.utils.validateEmail(email)) {

        UC.net.post(this.routes.email_exists, {email: email}, function (result) {

          if (result.exists) {
            error('This email address is already in use.');
          } else {
            dfd.resolve();
          }
        });
      } else {
        error('Please enter a valid email address');
      }
    },

    validateForm: function(dfd) {
      var user = this.$el.serializeJSON().user;
      var errors = [];

      if (user.FirstName == '') {
        errors.push({element: this.$('#user_FirstName'), text: 'Please provide a first name'});
      }

      if (user.LastName == '') {
        errors.push({element: this.$('#user_LastName'), text: 'Please provide a last name'});
      }

      if (UC.utils.urlIncludedInText(user.FirstName)) {
        errors.push({element: this.$('#user_FirstName'), text: 'Please provide a valid first name'});
      }

      if (UC.utils.urlIncludedInText(user.LastName)) {
        errors.push({element: this.$('#user_LastName'), text: 'Please provide a valid last name'});
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'input-container', scrollToFirst: false});
        dfd.reject();
      } else {
        dfd.resolve();
      }
    },

    validateInternational: function(dfd) {
      var user = this.$el.serializeJSON().user;
      var errors = [];

      if (!user.Address_attributes.City) {
        errors.push({element: this.$('#user_Address_attributes_City'), text: 'Please enter a city'});
      }

      if (!user.Address_attributes.CountryCode) {
        errors.push({element: this.$('#user_Address_attributes_CountryCode'), text: 'Please select a country'});
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'input-container', scrollToFirst: false});
        dfd.reject();
      } else {
        dfd.resolve();
      }
    },

    validateZipCode: function(dfd) {
      var $zip = this.$('#user_Address_attributes_ZipCode');
      var zip_code = $zip.val();
      var self = this;

      function error(msg) {
        var errors = [{element: $zip, text: msg}];
        UC.utils.showValidationErrors(errors, {className: 'input-container', scrollToFirst: false});
        dfd.reject();
      }

      if (UC.utils.validateZipCode(zip_code)) {
        UC.net.get(this.routes.validate_zip.format(zip_code), function (data) {
          dfd.resolve();
        }).fail(function (xhr) {
          xhr.handled = true;
          if (xhr.responseJSON) {
            error(self.invalidZipCodeErrorMessage(zip_code, xhr.responseJSON.message));
          } else {
            error('There was an error validating address. Please retry a little later');
          }
        });
      } else {
        error(self.invalidZipCodeErrorMessage(zip_code, 'Please enter a valid zip code'));
      }
    },

    invalidZipCodeErrorMessage: function(zip_code, message) {
      if (!zip_code || zip_code.trim().length === 0 || !this.showPhone) {
        return message;
      }

      return `Only US barred attorneys may sign up on UpCounsel. If you are US barred and simply living abroad, 
           please input a zip code reflecting an area you are barred in.`;
    },

    validatePhone: function(dfd, phone_number) {
      var $phone_el = this.$('#user_Phone');
      phone_number = phone_number.replace(/[\.)(+\s-]+/g, '');

      if (UC.utils.validatePhone(phone_number)) {
        if(!this.showZipCode){
          this.validateInternationalPhone($phone_el, phone_number) ? dfd.resolve() : dfd.reject();
        } else {
          dfd.resolve();
        }
      } else {
        UC.utils.showValidationErrors(
          [{element: $phone_el, text: 'Please provide a valid phone number'}],
          {className: 'input-container', scrollToFirst: false});
        dfd.reject();
      }
    },

    validateInternationalPhone: function(phone_el, phone_number) {
      if(phone_number.length < 11){
        UC.utils.showValidationErrors(
          [{element: phone_el, text: 'Please provide a valid phone number in international format'}],
          {className: 'input-container', scrollToFirst: false});
        return false;
      } else {
        return true;
      }
    }
  });
}();
