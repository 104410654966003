UC.views.AccountBilling = Backbone.View.extend({

  events: {
    'click .remove-payment-method' : 'removePaymentMethod',
    'click .default-payment-method' : 'defaultPaymentMethod',
    'click .default-payout-method' : 'defaultPayoutMethod'
  },

  initialize: function() {
    var plaidVerification = new UC.plaidBankVerification({
      openPlaidLinkSelector: '#open-plaid',
      plaidPubKey: UC.constants.plaidPubKey,
      plaidEnv: UC.constants.plaidEnv
    });

    plaidVerification.init();
  },

  removePaymentMethod: function(e) {
    e.preventDefault();

    function onConfirm(response) {
      if (response) {
        UC.net.post('/account/remove_payment_method/{0}'.format(accountId), null, function(res) {
          UC.utils.showNotification(res.message, UC.constants.results.success);
          $item.remove();
        });
      }
    }

    var $that = $(e.currentTarget);
    var accountId = $that.attr('accountId');
    var accountText = $('span[accountId="{0}"]'.format(accountId)).text();
    var $item = $that.parentsUntil('.pay-list', 'li');

    if ($item.hasClass('default-debit')) {
      UC.utils.showNotification('Unable to remove the default payment method', UC.constants.results.warning);
      return;
    } else {
      UC.utils.showConfirmModal('Confirm account deletion', 'Are you sure you want to remove {0}'.format(accountText), onConfirm);
    }
  },

  defaultPaymentMethod: function(e) {
    e.preventDefault();

    function onConfirm(response) {
      if (response) {
        UC.net.post('/account/set_default_payment_method/{0}'.format(accountId));
      }
    }

    var accountId = $(e.currentTarget).attr('accountId');
    var accountText = $('span[accountId="{0}"]'.format(accountId)).text();
    UC.utils.showConfirmModal('Confirm change', 'Are you sure you want to set {0} as the default payment method'.format(accountText), onConfirm);
  },

  defaultPayoutMethod: function(e) {
    e.preventDefault();

    function onConfirm(response) {
      if (response) {
        UC.net.post('/account/set_default_payout_method/{0}'.format(accountId));
      }
    }

    var accountId = $(e.currentTarget).attr('accountId');
    var accountText = $('span[accountId="{0}"]'.format(accountId)).text();
    UC.utils.showConfirmModal('Confirm change', 'Are you sure you want to set {0} as the default payout method'.format(accountText), onConfirm);
  }

});
