UC.views.ContentPagesSeoPages = Backbone.View.extend({
  events: {
    'click #prevAttorneysLink, #nextAttorneysLink': 'paginate',
    'nav-tab-clicked #law-report': 'addHashToBody',
    'nav-tab-clicked #top-rated-attorneys': 'removeHashFromBody'
  },
  
  initialize: function() {
    if (this.seoTagsExist() && window.location.hash === '#law-report') {
      $("a[href='#law-report']").trigger('click');
    }

    if($('.js-sticky-cta-test').length > 0) {
      this.showStickyFooter = true;
      var header = $('.js-how-it-works-hero');
      if(UC.utils.isMobileViewPort()) {
        header = $('.topic-cta-btn');
        $('.js-cancel-sticky-footer').click(this.hideStickyCtaFooter.bind(this));
      }
      UC.components.contentPage.setCtaBannerSize(header);
      var that = this;
      $(document).scroll(function () {
        if(that.showStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      });
    }
  },

  hideStickyCtaFooter: function() {
    this.showStickyFooter = false;
    UC.components.contentPage.removeCtaStickyFooter();
  },
  
  addHashToBody: function () {
    if (this.seoTagsExist()) {
      $('body').addClass('law-report');
      var scrollmem = $('html,body').scrollTop();
      window.location.hash = 'law-report';
      $('html,body').scrollTop(scrollmem);
    }
  },
  
  removeHashFromBody: function () {
    if (this.seoTagsExist()) {
      $('body').removeClass('law-report');
      window.location.hash = '';
    }
  },
  
  paginate: function(e) {
    e.preventDefault();
    $.get($(e.target).attr('href'), function(html) {
      $('#divAttorneyList').html(html);
      UC.utils.scrollToElement('#divAttorneyList', {
        offset: 200
      });
    });
  },
  
  seoTagsExist: function() {
    return UC.constants.pageTags.indexOf('seo law report') !== -1
  }
});
