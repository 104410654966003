import '../attorney_profile/attorney_created_reviews_modal'

!function() {

  UC.views.DashboardIndex = Backbone.View.extend({

    events: {
      'click #divFavoriteAttorneys .js-remove': 'removeFavoriteAttorney',
      'click #aExplainMetrics': 'explainMetrics',
      'click .show-invoice-link' : 'recordClickShowInvoiceLink',
      'click .js-pay-invoice-btn' : 'recordClickPayInvoiceBtn',
      'click .submit-wip-questionnaire-group' : 'submitWipQuestionnaireGroup',
      'click .skip-wip-questionnaire' : 'skipWipQuestionnaire',
      'change .wip-reason-radio-btn' : 'toggleOtherDescriptionTextarea',
      'click #aHideLaunchMeter' : 'hideLaunchMeter',
      'click #snoozeOnboardingChecklist' : 'snoozeOnboardingChecklist',
      'click #removeOnboardingChecklist' : 'removeOnboardingChecklist',
      'click .cancel-sched-call' : 'openCancelSchedCallModal',
      'click #db-track-time' : 'openTrackTimeModal',
      'click #btn-track-time-submit' : 'onSubmitTrackTimeModal',
      'click #btnAddNewWorkHistory': 'openEditWorkHistoryModal',
      'click #btnEditBiography': 'openEditBioModal',
      'click #btnAddPastClients': 'openAddPastClientsModal',
      'click #btnEditPastClients': 'openEditPastClientsModal',
      'click #btnEditPracticeAreas': 'openEditPracticeAreasModal',
      'click #btnEditIndustries': 'openEditIndustriesModal',
      'click #btnOpenLanguagesModal': 'openEditLanguagesModal',
      'click #btnUpdatePhoneNumber': 'openEditPhoneNumberModal',
      'click #requestW9FormLink': 'requestW9FormClicked',
      'click #search-jobs-link': 'redirectToJobsSearch',
    },

    initialize: function() {

      if (location.hash === '#intro') {
        this.initIntro();
      } else if (location.hash === '#w9-request') {
        this.requestW9Form();
      }

      switch(UC.constants.dashboard_type) {
        case 'index_for_atlas':
          this.initIndexForAtlas();
          break;
        case 'index_with_jobs':
          this.initIndexWithJobs();
          break;
      }

      $('.pulldown').popdown();
      UC.utils.recordKissClickEvent('.js-attorney-directhire', 'Dashboard - Direct Hire Attorney Click');

      $('#checklist-collapsible')
          .on('hide.bs.collapse', this.toggleOnboardingChecklistToggleIcon)
          .on('show.bs.collapse', this.toggleOnboardingChecklistToggleIcon);
    },

    toggleOnboardingChecklistToggleIcon: function() {
      $('#checklist-toggle i').toggleClass("icon-down-open icon-up-open");
    },

    initIndexForAtlas: function() {
      new UC.partials.DashboardIndexForAtlas({
        el: '#index-for-atlas'
      });
    },

    openEditBioModal: function(e) {
      this.$editBioModal.openBioModal(e);
    },
    openEditWorkHistoryModal: function(e) {
      this.$editWorkHistoryModal.openModal(e);
    },
    openAddPastClientsModal: function(e) {
      if (e) e.preventDefault();
      this.$addPastClientsModal.render();
    },
    openEditPastClientsModal: function(e) {
      if (e) e.preventDefault();
      this.$editPastClientsModal.render();
    },
    openEditPracticeAreasModal: function(e) {
      if (e) e.preventDefault();
      this.$openPracticeAreasModal.openModal();
    },
    openEditIndustriesModal: function(e) {
      if (e) e.preventDefault();
      this.$openEditIndustriesModal.openModal();
    },
    openEditLanguagesModal: function(e) {
      if (e) e.preventDefault();
      this.$openEditLanguagesModal.openModal();
    },
    openEditPhoneNumberModal: function(e) {
      if (e) e.preventDefault();
      this.$openEditPhoneNumberModal.render();
    },

    initIndexWithJobs: function() {

      this.$editWorkHistoryModal = new UC.modals.EditWorkHistory({
        updateWorkHistoryPagination: this.updateWorkHistoryPagination
      });
      this.$editBioModal = new UC.modals.EditBio({
        biographyContent: this.$('#biographyContent')
      });
      this.$addPastClientsModal = new UC.modals.AttorneyProfileAddPastClients();
      this.$editPastClientsModal = new UC.modals.AttorneyProfileEditPastClients();
      this.$openPracticeAreasModal = new UC.modals.EditPracticeAreas();
      this.$openEditIndustriesModal = new UC.modals.EditIndustries();
      this.$openEditLanguagesModal = new UC.modals.EditLanguages();
      this.$openEditPhoneNumberModal = new UC.modals.AttorneyProfileUpdatePhoneNumber();

      UC.attorneyProfileModals.mainModal.init();
      UC.attorneyCreatedReviewsModal.init('#btnAddReview');

      var $wipJobStatusUpdateModal = $('#wip-jobs-status-update-modal');

      this.initDashboardScheduledCalls();

      if ($wipJobStatusUpdateModal.length > 0) {
        $wipJobStatusUpdateModal.modal({
          backdrop: 'static'
        });
        UC.utils.recordKissEvent('attorney_wip_job_status_questionnaire_shown', {attorney_id: UC.user.id, location: 'dashboard'});
        $('.submit-wip-questionnaire-group').each(function(index, element) {
          var $element = $(element);
          $element.data('buttonLoader', $element.buttonLoader());
        })
      }

      if ($('#js-att-dashboard-metrics').length > 0) {
        new UC.views.DashboardMetrics({
          el: '#js-att-dashboard-metrics'
        });
      }

      if ($('#recent-jobs').length > 0) {
        new UC.views.DashboardJobsList({
          el: '#recent-jobs'
        });
      }

      if (UC.constants.hasPayout && UC.constants.unverifiedAccount) {
        this.$('#modalUnverifiedAccount').modal();
      }

      if (this.$('#modalClaimedReferral').length > 0) {
        UC.utils.showModal(this.$('#modalClaimedReferral'));
      }

      if (this.$('#btnShowUploadResume').length > 0) {
        this.$('#btnShowUploadResume').click(this.showResumeUpload.bind(this));

        this.uploader = new UC.components.Upload({
          source_type: 'checklist_resume',
          upload_path: '{0}/resumes/'.format(UC.user.id),
          createPath: '/account/upload_resume',
          maxFiles: 1,
          processFailureCallback: this.resumeUploadFailure.bind(this),
          onUploadComplete: this.resumeUploadSuccess.bind(this),
          filePickerKey: 'APtS2ec0eQhieFIz8e0xhz',
          skipPathPrefix: true
        });
      }

      this.initTracking();
    },

    initTracking: function() {
      var userType = UC.user.type;

      UC.utils.recordKissEvent('Viewed Dashboard', {'type':userType});
      UC.utils.recordKissClickEvent('db-create-client', 'Dashboard - Create Pulldown Click', {'action':'client', 'type':userType});
      UC.utils.recordKissClickEvent('db-create-invoice', 'Dashboard - Create Pulldown Click', {'action':'invoice', 'type':userType});
      UC.utils.recordKissClickEvent('db-create-project', 'Dashboard - Create Pulldown Click', {'action':'project', 'type':userType});
      UC.utils.recordKissClickEvent('db-track-time', 'Dashboard - Create Pulldown Click', {'action':'track_time', 'type':userType});

      UC.utils.recordKissClickEvent('createBtn', 'Dashboard - Create Button Click');
      UC.utils.recordKissClickEvent('aExplainMetrics', 'Dashboard - whats this link');

      UC.utils.recordKissClickEvent('#referral-later', 'Dashboard - Referrals Later Btn Click');
      UC.utils.recordKissClickEvent('#referral-close', 'Dashboard - Referrals Close Click');
      UC.utils.recordKissClickEvent('#referral-link', 'Dashboard - Refer a friend Btn Click (banner)');
      UC.utils.recordKissClickEvent('#referral-side-link', 'Dashboard - Refer a friend Btn Click (side ad)');
    },

    setReferralBannerHelpDisplay: function(value, callback) {
      UC.net.post('/account/update_help_display_option', {
        option: 'referral_dashboard_banner',
        optionValue: value
      });
    },

    recordClickShowInvoiceLink: function(e) {
      var invoiceId = $(e.currentTarget).closest('.js-awaiting-payment-invoice').data('invoiceId');
      UC.utils.recordKissEvent('Dashboard - Click Show Pending Invoice Widget Link', {invoiceId: invoiceId});
    },

    recordClickPayInvoiceBtn: function(e) {
      var invoiceId = $(e.currentTarget).closest('.js-awaiting-payment-invoice').data('invoiceId');
      UC.utils.recordKissEvent('Dashboard - Click Pay Pending Invoice Widget Btn', {invoiceId: invoiceId});
    },

    questionnaireReponsesValid: function() {
      var errors = [];

      $('.wip-job-status-questionnaire').removeClass('has-error');
      $('.wip-job-status-questionnaire .error-message').text('');

      $('.active-questionnaire-group .wip-job-status-questionnaire').each(function(index, element) {
        var $element = $(element);
        var wipReason = $element.find('.wip-reason-radio-btn:checked').val();

        if (!wipReason) {
          errors.push({element: $element, text: 'You must select an option'});
        }
      });

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'wip-job-status-questionnaire'});
        return false
      } else {
        return true
      }

    },

    skipWipQuestionnaire: function(e) {
      e.preventDefault();

      $('#wip-jobs-status-update-modal').modal('hide');

      UC.components.confirmModal({
        title: 'Confirm',
        text: 'Are you sure you want to cancel status update for your work in progress jobs for today?',
        callback: function (confirmed) {
          if (confirmed) {
            $.post('jobs/skip_job_status_update');
          } else {
            $('#wip-jobs-status-update-modal').modal('show');
          }
        },
        yesText: 'Yes, skip for today',
        noText: 'No, back to the update'
      });
    },

    submitWipQuestionnaireGroup: function(e) {
      e.preventDefault();
      var $nextButton = $(e.currentTarget);
      var nextGroupNum = $nextButton.data('nextGroup');
      var $buttonLoader = $nextButton.data('buttonLoader');

      if (this.questionnaireReponsesValid()) {
        if ($buttonLoader) $buttonLoader.showLoader();

        var responses = this.getActiveQuestionnaireResponses();

        var ajaxRequest = $.ajax({
          url: 'jobs/wip_job_status_update',
          type: 'POST',
          data: {wip_questionnaire_responses: responses},
          context: this
        });

        ajaxRequest.done(function() {
          if ($buttonLoader) $buttonLoader.hideLoader();

          if (nextGroupNum) {
            this.showWipQuestionnaireGroup(nextGroupNum);
          } else {
            $('#wip-jobs-status-update-modal').modal('hide');
          }
        });

        ajaxRequest.fail(function() {
          $('#wip-jobs-status-update-modal').modal('hide');
          if ($buttonLoader) $buttonLoader.hideLoader();
        });
      }

    },

    getActiveQuestionnaireResponses: function() {
      var responses = {};
      $('.active-questionnaire-group .wip-job-status-questionnaire').each(function(index, element) {
        var $element = $(element);
        var jobId = $element.data('jobId');
        var wipReason = $element.find('.wip-reason-radio-btn:checked').val();
        var description = $element.find('.other-description').val();

        responses[jobId] = {wip_reason: wipReason, description: description};
      });
      return responses;
    },

    showWipQuestionnaireGroup: function(groupNum) {
      $('.wip-job-status-questionnaire-group').removeClass('active-questionnaire-group');
      $(".wip-job-status-questionnaire-group[data-questionnaire-group='"+ groupNum +"']").addClass('active-questionnaire-group');
    },

    toggleOtherDescriptionTextarea: function(e) {
      var $radioBtn = $(e.currentTarget);
      var $parentQuestionnaire = $radioBtn.parents('.wip-job-status-questionnaire');

      if ($radioBtn.val() === 'Other') {
        $parentQuestionnaire.find('.other-description').removeClass('hidden');
      } else {
        $parentQuestionnaire.find('.other-description').addClass('hidden');
      }
    },

    explainMetrics: function(e) {
      e.preventDefault();

      this.$el.find('#modalExplainMetrics').modal();
    },

    removeFavoriteAttorney: function(e) {
      e.preventDefault();
      var $attorney = $(e.currentTarget).closest('.js-attorney');
      var attorney_id = $attorney.data('id');

      var $favorite_attorneys = this.$('#divFavoriteAttorneys');

      UC.utils.showConfirmModal('Confirm', 'Are you sure you want to remove the attorney from your dashboard?', function(response) {

        if (response) {

          UC.net.destroy('/dashboard/favorite_attorneys/{0}'.format(attorney_id), null, function() {
            UC.utils.showNotificationSuccess("Attorney was successfully removed.");
            $attorney.remove();

            if ($favorite_attorneys.find('.js-attorney').length < 1) {
              $favorite_attorneys.remove();
            }
          });
        }
      });
    },

    showResumeUpload: function(e) {
      e.preventDefault();
      this.uploader.openFilePicker({});
    },

    resumeUploadFailure: function(err) {
      UC.utils.showNotification(err, UC.constants.results.failure);
    },

    resumeUploadSuccess: function() {
      UC.utils.checklistSuccessCallback('btnShowUploadResume');
    },

    initIntro: function() {
      var steps = [
        {
          element: UC.utils.isMobileViewPort() ? $('#menu-toggle') : $('#user-menu'),
          text: 'Click the icon on the top right of the screen to edit your account settings and billing information.',
          position: 'left'
        },
        {
          element: $('#recent-jobs'),
          text: 'The dashboard will display a summary of your recent jobs, as well as their status. Click on a job to edit the post, view proposals, send messages, etc.',
          position: 'top'
        }
      ];

      $('#user-menu').addClass('forced');

      function reset() {
        $('#user-menu').removeClass('forced');
      }

      UC.utils.initIntroJs(steps).onbeforechange(function(el) {
        var $el = $(el);
        var step = $el.data('step');
        var isMobileViewPort = UC.utils.isMobileViewPort();

        if (step == 1) {
          $('#user-menu').addClass('forced');
          if (!isMobileViewPort) {
            $('.introjs-helperNumberLayer').addClass('introjs-fix-top-step');
          }
        } else {
          $('#user-menu').removeClass('forced');
          if (!isMobileViewPort) {
            $('.introjs-helperNumberLayer').removeClass('introjs-fix-top-step');
          }
        }

        UC.utils.recordKissEvent('Product tour next step', {
          'step': step,
          'page-path': window.location.pathname
        });
      }).onexit(reset).oncomplete(reset);

      $('.introjs-overlay').addClass('no-transition');
      if (!UC.utils.isMobileViewPort()) {
        $('.introjs-helperNumberLayer').addClass('introjs-fix-top-step');
      }

      UC.utils.recordKissEvent('Product tour started', {
        'page-path': window.location.pathname
      });
    },

    initDashboardScheduledCalls: function() {
      var scheduled_call_id = UC.utils.getParameterByName('scheduled_call_reply');
      var dashboard_scheduled_calls = {};

      if ($('#scheduled-calls').length > 0) {
        dashboard_scheduled_calls = new UC.views.DashboardScheduledCalls({
          el: '#scheduled-calls'
        });
      }

      if (scheduled_call_id) {
        new UC.modals.ScheduledCallsShow({
          scheduled_call_id: scheduled_call_id,
          callback: function() {
            // if it exists we want it to re-render
            dashboard_scheduled_calls.render();
          }
        }).render();
      }
    },

    hideLaunchMeter: function(e) {
      UC.net.post('/account/hide_launch_meter', function(res) {
        UC.utils.showNotificationSuccess(res.message);
        this.$el.find('#js-checklistContainer').hide();
      }.bind(this));
    },

    hideOnboardingChecklist: function(snooze) {
      UC.net.post('/account/hide_onboarding_checklist', {snooze: snooze}, function(res) {
        UC.utils.showNotificationSuccess(res.message);
        this.$el.find('#js-checklistContainer').hide();
      }.bind(this));
    },

    snoozeOnboardingChecklist: function(e) {
      e.preventDefault();

      UC.utils.showConfirmModal(
          'Confirm',
          'Are you sure want to hide the onboarding checklist for the next week?',
          function (confirmed) {
            if (confirmed) {
              this.hideOnboardingChecklist(true);
            }
          }.bind(this)
      );
    },

    removeOnboardingChecklist: function(e) {
      e.preventDefault();

      UC.utils.showConfirmModal(
          'Confirm',
          "Are you sure you don't want to see the onboarding checklist anymore?",
          function (confirmed) {
            if (confirmed) {
              this.hideOnboardingChecklist(false);
            }
          }.bind(this)
      );
    },

    openCancelSchedCallModal: function(e) {
      e.preventDefault();
      var scheduledCallId = $(e.currentTarget).data('scheduled-call-id');
      new UC.partials.ScheduledCallsCancel({scheduledCallId: scheduledCallId});
    },

    openTrackTimeModal: function (event) {
      event.preventDefault();

      var $modalTrackTime = this.$el.find('#modalTrackTime');

      $modalTrackTime.find('#track-time-client-select').select2({width: '100%'});
      $modalTrackTime.modal();
    },

    onSubmitTrackTimeModal: function (event) {
      event.preventDefault();
      var attorneyClientId = this.$el.find('#track-time-client-select').val();

      UC.utils.recordKissEvent('track_time_modal_selected_client', {'attorneyClientId': attorneyClientId});

      if (attorneyClientId) {
        location.href = '/clients/{0}?current_ts_entries=true'.format(attorneyClientId);
      }
    },

    requestW9FormClicked: function(e) {
      e.preventDefault();

      const $link = $(e.currentTarget)
      const originalLinkText = $link.text();
      $link.text('')
      const loader = $link.buttonLoader();
      loader.showLoader();
      setTimeout(function() {
        loader.hideLoader();
        $link.text(originalLinkText);
      }, 3000);

      this.requestW9Form();
    },

    requestW9Form: function() {
      const self = this;
      UC.net.get('/account/w9_form/request', {})
          .done(function(response) {
            if (response.form_request) {
              window.Avalara1099.requestW9(response.form_request, {prefill: response.prefill_data})
                  .then((request) => {
                    const attributes = (request.data || request).attributes;
                    const signedAt = attributes.signed_at && new Date(attributes.signed_at);
                    if (signedAt) {
                      UC.net.post('/account/w9_form/complete', attributes)
                          .done(function () {
                            self.showW9CompletedDialog(request.links && request.links.signed_pdf)
                          })
                          .fail(function () {
                            UC.utils.showNotification('There was an error reporting back your W-9 form. Please try again later', UC.constants.results.failure);
                          });
                    }
                  })
                  .catch((error) => {
                    console.warn(error)
                    UC.utils.showNotification('There was an error processing your W-9 form. Please try again later', UC.constants.results.failure);
                  })
            }
          })
          .fail(function() {
            UC.utils.showNotification('There was an error requesting for W-9 form. Please try again later', UC.constants.results.failure);
          });
    },

    showW9CompletedDialog: function(signedPdfLink) {
      const self = this;
      let message = 'UpCounsel Technologies Inc. has received your W-9 form.'
      if (signedPdfLink) {
        message += ` You can also <a href="${signedPdfLink}">download</a> a copy for your records or make changes.`
      }
      new UC.partials.modalV9 ({
        modalTitle: 'Thank you!',
        modalContent: message,
        modalFooter: {
          yesTextClass: 'btn btn-md btn-primary',
          yesText: 'Close',
          noText: 'Revise',
          noTextId: 'reviseW9Form',
          noTextClass: 'btn btn-md btn-grey-BG'
        },
        renderCallback: function() {
          $('#w9-alert').remove();
          $('#reviseW9Form').on('click', function(e) {
            self.requestW9Form();
          })
        }
      });
    },

    redirectToJobsSearch: function(e) {
      e.preventDefault();
      const filteredJobsType = $('#recent-jobs ul li.active a').data('type');
      window.location = `/jobs?type=${filteredJobsType}`
    },
  });
}();
